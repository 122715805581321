import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Login from "./Login";

const LoginLogic = () => {
    const [postObj, setPostObj] = useState({});
    const [passwordResetIsOpen, setPasswordResetIsOpen] = useState(false);
    const [resetMessage, setResetMessage] = useState();
    const navigate = useNavigate();
    const { REACT_APP_API_URL } = process.env;
    const [error, setError] = useState();
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ipAddress, setIpAddress] = useState();

    useEffect(() => {
        const getIpAddress = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                return data.ip;
            } catch (error) {
                console.error('Error fetching IP address:', error);
                return null;
            }
        };
        getIpAddress().then((ip) => {
            setIpAddress(ip);
        });
    }, []);

    const HandleSubmit = (event) => {
        setError(null)
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            fetch(`${REACT_APP_API_URL}/LoginV2?username=${postObj.username}&password=${encodeURIComponent(postObj.password)}&ipAddress=${ipAddress}`)
                .then(res => {
                    if (res.status === 200) {
                        res.json().then(text => {
                            if (text.message === 'Reset') {
                                setPasswordResetIsOpen(true);
                            } else {
                                navigate('/TwoFactorAuth', {state: { username: postObj.username }})
                            }
                        })
                    } else if (res.status === 400 || res.status === 404) {
                        setLoading(false);
                        setError('Invalid Credentials, please try again or contact the system administrator')
                    } else if(!res.ok){
                        throw new Error(res)
                    }
                })
                .catch(err => {
                    console.error(err)
                    setError(err.text)
                    setLoading(false)
                })
        }
        setValidated(true);
        // navigate('/PasswordReset')
    }

    const HandleChange = (e, fieldName) => {
        setError(null)
        setResetMessage(null)
        postObj[fieldName] = e.target.value;
        setPostObj(postObj)
    }
    return (
        <Login
            HandleSubmit={HandleSubmit}
            validated={validated}
            HandleChange={HandleChange}
            passwordResetIsOpen={passwordResetIsOpen}
            setPasswordResetIsOpen={setPasswordResetIsOpen}
            postObj={postObj}
            error={error}
            resetMessage={resetMessage}
            setResetMessage={setResetMessage}
            loading={loading}
        ></Login>
    )
}

export default LoginLogic;