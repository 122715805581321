import React from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AdverseCarrier({ adverseCarrier }) {
  return (
    <Card className='padding'>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Adverse Carrier</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.adverseCarrier || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Adverse Adjuster</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.adverseAdjuster || ''} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Address Line 1</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.address1 || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.address2 || ''} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>City</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.city || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>State</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.state || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Zip</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.zip || ''} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Phone</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.tel1 || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Phone Ext</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.ext1 || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Phone Two</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.tel2 || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Phone Two Ext</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.ext2 || ''} />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Fax</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.fax || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Claim</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.claim || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>TPA</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.tpa || ''} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Email</Form.Label>
          <Form.Control readOnly value={adverseCarrier?.email || ''} />
        </Form.Group>
      </Row>
    </Card>
  );
}

export default AdverseCarrier;